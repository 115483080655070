import * as React from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useFormik } from 'formik';
import * as yup from 'yup';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, TextField , MenuItem, CircularProgress } from '@mui/material';
// components
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// components
import { isEmpty } from 'lodash';
import moment from 'moment';
import useFetch from "./useFetch";
import {getApiLink, getToken} from '../helpers';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ----------------------------------------------------------------------
const countryList = [
    "Egypt",
    "Saudi Arabia",
	"Oman",
    "United Arab Emirates",
	"Qatar",
    "Sudan",
	"Libya",
    "-------------------------------------",
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas (the)",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia (Plurinational State of)",
	"Bonaire, Sint Eustatius and Saba",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory (the)",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cayman Islands (the)",
	"Central African Republic (the)",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands (the)",
	"Colombia",
	"Comoros (the)",
	"Congo (the Democratic Republic of the)",
	"Congo (the)",
	"Cook Islands (the)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Curaçao",
	"Cyprus",
	"Czechia",
	"Côte d'Ivoire",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic (the)",
	"Ecuador",

	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Falkland Islands (the) [Malvinas]",
	"Faroe Islands (the)",
	"Fiji",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories (the)",
	"Gabon",
	"Gambia (the)",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (the)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Isle of Man",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea (the Democratic People's Republic of)",
	"Korea (the Republic of)",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic (the)",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",

	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands (the)",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia (Federated States of)",
	"Moldova (the Republic of)",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands (the)",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger (the)",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"Northern Mariana Islands (the)",
	"Norway",

	"Pakistan",
	"Palau",
	"Palestine, State of",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines (the)",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",

	"Republic of North Macedonia",
	"Romania",
	"Russian Federation (the)",
	"Rwanda",
	"Réunion",
	"Saint Barthélemy",
	"Saint Helena, Ascension and Tristan da Cunha",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Martin (French part)",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",

	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Sint Maarten (Dutch part)",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",

	"Suriname",
	"Svalbard and Jan Mayen",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands (the)",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Kingdom of Great Britain and Northern Ireland (the)",
	"United States Minor Outlying Islands (the)",
	"United States of America (the)",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela (Bolivarian Republic of)",
	"Viet Nam",
	"Virgin Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
	"Åland Islands"
];

const PaymentsWays = [
    "Droplen","Vodafone Cash","Bank Transfer", "Other"
]
const currencies = [
     "$", "EGP"
];

const promocode = [
    "CKTIAN2022", "CKTIAN2023"
];

const status = [
    "Pending" , "Paid", "No Response", "Lost"
];
const statusCand = [
    "Paid", "Pending" ,"Cancelled", "Refunded"
];
const Usersource = [
    "Website","Friend Referral","FB Ads", "Instgram Ads", "FB", "Instgram" ,"LinkedIn", "Youtube", "Twitter", "Whatsapp", "Other"
];

const validationSchema = yup.object({
    CandId: yup
        .string('Enter Candidate ID')
        .min(4, 'Candidate ID should be of minimum 4 chars')
        .required('Candidate ID is required'),
    name: yup
        .string('Enter Candidate Name')
        .min(3, 'Candidate Name should be of minimum 3 chars')
        .required('Candidate Name is required'),
    email: yup
        .string().email('Must be a valid email')
        .max(255)
        .required('Candidate Email is required'),
});

const validationSchemaLead = yup.object({
    // name: yup
    //     .string('Enter Candidate Name')
    //     .min(3, 'Candidate Name should be of minimum 3 chars')
    //     .required('Candidate Name is required'),
});


async function updateCandidate(credentials) {
    const url = getApiLink(`admin/update-ckt-user/${credentials.ID}`);
    const token = getToken();
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        cand_id: credentials.CandId,
        cand_name:credentials.candName,
        cand_email:credentials.candEmail,
        phone:credentials.candPhone,
        country:credentials.candCountry,
        package_type:credentials.candPackageType,
        packages:credentials.candPackage,
        paymentWay:credentials.candPaymentWay,
        installment:credentials.candInstallemnt,
        installment_start_date:credentials.candInstallemntStartDate,
        installment_end_date:credentials.candInstallemntEndDate,
        total_paid:credentials.candTotalAmount,
        currency:credentials.candCurrency,
        payment_date:credentials.candPaymentDate,
        status:credentials.candStatus,
        source:credentials.candsource,
        notes:credentials.candnotes
      })
    })
      .then(data => data.json())
}
async function updateLead(credentials) {
    const url = getApiLink(`admin/update-ckt-lead/${credentials.LeadID}`);
    const token = getToken();
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        fullName:credentials.LeadName,
        email:credentials.LeadEmail,
        phone:credentials.LeadPhone,
        country:credentials.LeadCountry,
        package_type:credentials.LeadPackageType,
        packages:credentials.LeadPackage,
        paymentWay:credentials.LeadPaymentWay,
        installment:credentials.LeadInstallemnt,
        promoCode:credentials.LeadPromoCode,
        lead_source:credentials.Leadsource,
        notes:credentials.Leadnotes,
        contact_date:credentials.LeadContactDate,
        deal_done_date:credentials.LeadDealDoneDate,
        status:credentials.LeadStatus,
      })
    })
      .then(data => data.json())
}

export default function EditUser() {
    const { state } = useLocation();
    const {id} = useParams();
    const theme = useTheme();
    const {data,isPending,error} = useFetch(getApiLink('admin/get-all-packages'));
    const {data:packagesTypesValue,isPending2,error2} = useFetch(getApiLink('admin/get-all-packages-types'));
    // Add Canidate
    // const LeadPackagesList = ["Questions UWorld Package", "Medical Package", "PassMedicine Package", "OSCE Package"];
    const [Candphone, setCandphone] = React.useState(state._CandId ?state._candPhone : state._phone);
    const [packageType, setpackageType] = React.useState(state._CandId ? state._candPackageType : state._packagetype);
    const [country, setCountry] = React.useState(state._CandId ? state._candCountry : state._country);
    const [LeadPackagesList, setLeadPackagesList] = React.useState([]);
    async function getLeadPackagesList(){
        await data.data.map((option) => (
            (!((state._CandId ? state._candPackage.split(",").length : state._pack.split(",").length) <= LeadPackagesList.length) && (state._CandId ? state._candPackage.includes(option.package_name) : state._pack.includes(option.package_name))) ?
            LeadPackagesList.push(option.package_name) : option.package_name
        ));
    }
    getLeadPackagesList();
    const [LoadingButton, setLoadingButton] = React.useState("0");
    const [ErrorHandling, setErrorHandling] = React.useState("");
    const [ErrorInstallmentDateHandling, setErrorInstallmentDateHandling] = React.useState("");
    const [ErrorPaymentDateHandling, setErrorPaymentDateHandling] = React.useState("");
    const [Cktpackage, setCktpackage] = React.useState(LeadPackagesList);
    const [PaymentWay, setCktPaymentWay] = React.useState(state._CandId ? state._candPaymentWay: state._paymentWay);
    const [InstallemntStatus, setInstallemntStatus] = React.useState();
    const [Installemnt, setInstallemnt] = React.useState(state._CandId ? state._candInstallemnt : state._installment);
    const [InstallemntStartDate, setInstallemntstartDate] = React.useState(state._candInstallemntStartDate);
    const [InstallemntEndDate, setInstallemntEndDate] = React.useState(state._candInstallemntEndDate);
    const [TotalAmount, setTotalAmount] = React.useState(state._candTotalAmount);
    const [Currency, setCurrency] = React.useState(state._candCurrency);
    const [PaymentDate, setPaymentDate] = React.useState(state._CandId ? state._candPaymentDate: state._expectedpaymentdate);
    const [Status, setStatus] = React.useState(state._CandId ? state._candStatus: state._status);
    

    /* Lead */
    const [DealDoneDate, setDealDoneDate] = React.useState(state._dealdonedate);
    const [ContactDate, setContactDate] = React.useState(state._contactdate);
    const [PromoCode, setPromoCode] = React.useState(state._promoCode);
    const [leadSource, setleadSource] = React.useState(state._CandId ? state._candsource : state._leadsource);
    const handleChangeDealDoneDate = (event) => {
        setDealDoneDate(event.target.value);
    };

    const handleChangeContactDate = (event) => {
        setContactDate(event.target.value);
    };

    const handleChangePromoCode = (event) => {
        setPromoCode(event.target.value);
    };
    const handleChangeleadSource = (event) => {
        setleadSource(event.target.value);
    };
    /**/

    const handleChange = (event) => {
        setpackageType(event.target.value);
    };
    const handleChangePaymentDate = (event) => {
        setPaymentDate(event.target.value);
    }
    const handleChangeInstallemnt = (event) => {
        setInstallemnt(event.target.value);
        if (event.target.value === "Yes"){
            setInstallemntStatus("Yes");
        }else{
            setInstallemntStatus("No");
            setInstallemntstartDate(null);
            setInstallemntEndDate(null);
        }
    };
    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value);
    }
    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
    }
    const handleChangeTotalAmount = (event) => {
        setTotalAmount(event.target.value);
        if (Currency === 'EGP'){
            setCurrency('EGP');
        }else{
            setCurrency('$');
        }
    };
    const handleChangeInstallemntStartDate = (event) => {
        setInstallemntstartDate(event.target.value);
    };
    const handleChangeInstallemntEndDate = (event) => {
        setInstallemntEndDate(event.target.value);
    };
    const handleChangePaymentWay = (event) => {
        setCktPaymentWay(event.target.value);
    };
    const handleChangePackages = (event) => {
        const {
            target: { value },
          } = event;
          setCktpackage(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
    };
    const handleChangeCountries = (event) => {
          setCountry(event.target.value);
    };
    const formik = useFormik({
        initialValues: {
            CandId:state._CandId,
            name:state._candName,
            email:state._candEmail,
            phone:state._candPhone,
            source:state._candsource,
            notes:state._candnotes,
        },
        validationSchema,
        onSubmit: async (values) => {
            const ID = id;
            const CandId = values.CandId;
            const candName = values.name;
            const candEmail = values.email;
            const candPhone = Candphone;
            const candCountry = country;
            const candPackage = Cktpackage;
            const candPackageType = packageType;
            const candPaymentWay = PaymentWay;
            const candInstallemnt = Installemnt;
            const candInstallemntStartDate = InstallemntStartDate;
            const candInstallemntEndDate = InstallemntEndDate;
            const candCurrency = Currency;
            const candTotalAmount = TotalAmount;
            const candPaymentDate = PaymentDate;
            const candStatus = Status;
            const candsource = leadSource;
            const candnotes = values.notes;
            if(candPhone.length <=4 || isEmpty(candPaymentDate) || isEmpty(candTotalAmount) || isEmpty(candPackage) || isEmpty(candPackageType) || isEmpty(candsource)){
                setErrorHandling("* Required Field");
            }else if(candInstallemnt === "Yes" && (isEmpty(candInstallemntStartDate) || isEmpty(candInstallemntEndDate))){
                setErrorHandling("* Required Field");
            }else if (candInstallemnt === "Yes" && moment(candInstallemntStartDate).isSameOrAfter(candInstallemntEndDate)){
                setErrorInstallmentDateHandling("* End Date should be after the start date");
            }else if(moment(candPaymentDate).isAfter(moment())){
                setErrorPaymentDateHandling("* Payment Date Can't be in a late date than today");
            }else{
            setLoadingButton("1");
            setErrorHandling("");
            setErrorPaymentDateHandling("");
            setErrorInstallmentDateHandling("");
            const response = await updateCandidate({
                ID,
                CandId,
                candName,
                candEmail,
                candPhone,
                candCountry,
                candPackage,
                candPackageType,
                candPaymentWay,
                candInstallemnt,
                candInstallemntStartDate,
                candInstallemntEndDate,
                candCurrency,
                candTotalAmount,
                candPaymentDate,
                candStatus,
                candsource,
                candnotes
            });
            if (response.status) {
                swal("Success", response.message, "success", {
                  buttons: false,
                  timer: 1000,
                })
                .then((value) => {
                  window.location.href = "/dashboard/candidates";
                });
            } else {
                swal("Failed", response.message, "error");
                setLoadingButton("0");
            }
        }
        },
    });

    const formikLead = useFormik({
        initialValues: {
            name:state._fullName,
            email:state._email,
            phone:state._phone,
            source:state._leadsource,
            notes:state._notes,
        },
        validationSchemaLead,
        onSubmit: async (values) => {
            const LeadID = id;
            const LeadName = values.name;
            const LeadEmail = values.email;
            const LeadPhone = Candphone;
            const LeadCountry = country;
            const LeadPackage = Cktpackage;
            const LeadPackageType = packageType;
            const LeadPaymentWay = PaymentWay;
            const LeadInstallemnt = Installemnt;
            const LeadDealDoneDate = DealDoneDate;
            const LeadContactDate = ContactDate;
            const LeadPromoCode = PromoCode;
            const LeadStatus = Status;
            const Leadsource = leadSource;
            const Leadnotes = values.notes;
            if(moment(LeadDealDoneDate).isAfter(moment()) || moment(LeadContactDate).isAfter(moment())){
                setErrorPaymentDateHandling("* Date Can't be in a late date than today");
            // }else if(LeadPhone.length <=4 || isEmpty(LeadName) || isEmpty(LeadContactDate) || isEmpty(LeadPackage) || isEmpty(LeadPackageType) || isEmpty(Leadsource)){
            }else if(LeadPhone.length <=4 || isEmpty(LeadContactDate) || isEmpty(Leadsource)){
                setErrorHandling("* Required Field");
            }else{
            setLoadingButton("1");
            setErrorHandling("");
            setErrorPaymentDateHandling("");
            const response = await updateLead({
                LeadID,
                LeadName,
                LeadEmail,
                LeadPhone,
                LeadCountry,
                LeadPackage,
                LeadPackageType,
                LeadPaymentWay,
                LeadInstallemnt,
                LeadDealDoneDate,
                LeadContactDate,
                LeadPromoCode,
                LeadStatus,
                Leadsource,
                Leadnotes
            });
            if (response.status) {
                swal("Success", response.message, "success", {
                  buttons: false,
                  timer: 1000,
                })
                .then((value) => {
                  window.location.href = "/dashboard/leads";
                });
            } else {
                swal("Failed", response.message, "error");
                setLoadingButton("0");
            }
            }
        },
    });
  if(!data){
    return (<CircularProgress />);
  }
  return (
   state._CandId ?
   <Page title="Edit Candidate">
   <Box sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}}autoComplete="on">
       <div>
               <Typography align="left" variant="h4" sx={{ mb: 5 }} style={{marginLeft:'7px'}}>
               Edit Candidate
               </Typography>
           <form onSubmit={formik.handleSubmit}>
               <>
               <TextField
                   id="CandId"
                   name="CandId"
                   label="Cand Id"
                   type="text"
                   value={formik.values.CandId}
                   onChange={formik.handleChange}
                   error={formik.touched.CandId && Boolean(formik.errors.CandId)}
                   helperText={formik.touched.CandId && formik.errors.CandId}
               /><br />
               <TextField
                   id="name"
                   name="name"
                   label="Candidate Name"
                   type="text"
                   value={formik.values.name}
                   onChange={formik.handleChange}
                   error={formik.touched.name && Boolean(formik.errors.name)}
                   helperText={formik.touched.name && formik.errors.name}
               /><br />
               <TextField
                   id="email"
                   name="email"
                   label="Candidate Email"
                   type="email"
                   value={formik.values.email}
                   onChange={formik.handleChange}
                   error={formik.touched.email && Boolean(formik.errors.email)}
                   helperText={formik.touched.email && formik.errors.email}
               />
               
               </>
               <><InputLabel id="demo-multiple-chip-label" style = {{marginTop:'7px', marginLeft:'9px'}}>Enter Candidate Phone</InputLabel>
               <PhoneInput
                id="phone"
                name ='phone'
                label="Candidate Phone"
                type="text"
                   inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true
                   }}
                   enableSearch
                   inputStyle={{height:'57px', width:'244px'}}
                   containerStyle={{marginLeft:'8px'}}
                   preferredCountries={['eg','sa','om','ae','sd','qa','ly']}
                   country={'eg'}
                   excludeCountries = {'ISR'}
                   value={Candphone}
                   onChange={setCandphone}
               />
               <p style={{color:'red'}}>{!Candphone && ErrorHandling}</p>
               <br />
               <TextField
               id="outlined-select-currency-native"
               select
               label="Select Country"
               value={country}
               onChange={handleChangeCountries}
               SelectProps={{
                   native: true,
               }}
               >
               {countryList.map((option) => (
                   <option key={option} value={option}>
                   {option}
                   </option>
               ))}
               </TextField><br />
               </>
               <InputLabel id="demo-multiple-chip-label" style = {{marginTop:'7px', marginLeft:'9px'}}>Select Packages</InputLabel>
               {data && <Select
               labelId="demo-multiple-chip-label"
               id="demo-multiple-chip"
               multiple
               displayEmpty
               value={Cktpackage}
               onChange={handleChangePackages}
               input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
               renderValue={(selected) => {
                   if (selected.length === 0) {
                       return <em>Select Packages</em>;
                   }
                   return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                   {selected.map((value) => (
                       <Chip key={value} label={value} />
                   ))}
                   </Box>

               }}
               MenuProps={MenuProps}
               style = {{marginTop:'9px', marginLeft:'9px', width:'245px'}}
               >
               {data.data.map((name) => (
                   <MenuItem
                   key={name.package_name}
                   value={name.package_name}
                   
                   >
                   {name.package_name}
                   </MenuItem>
               ))}
               </Select>}
               <p style={{color:'red'}}>{isEmpty(Cktpackage) && ErrorHandling}</p>
               <br />
               {packagesTypesValue && <TextField
               id="outlined-select-currency-native"
               select
               label="Select Package Type"
               value={packageType}
               onChange={handleChange}
               SelectProps={{
                   native: true,
               }}
               >
                <option key="Select Package Type" value="Select Package Type">
                        Select Package Type
                </option>
               {packagesTypesValue.data.map((option) => (
                   <option key={option.package_type} value={option.package_type}>
                   {option.package_type}
                   </option>
               ))}
               </TextField>}
               <p style={{color:'red'}}>{isEmpty(packageType) && ErrorHandling}</p>
               <br />
               {isPending && isPending2 && <CircularProgress />}
               {error && error2 && <div>{error}{' '}{error2}</div>}
               <></>
               <TextField
               id="outlined-select-currency-native"
               select
               label="Select Payment Way"
               value={PaymentWay}
               onChange={handleChangePaymentWay}
               SelectProps={{
                   native: true,
               }}
               >
               {PaymentsWays.map((option) => (
                   <option key={option} value={option}>
                   {option}
                   </option>
               ))}
               </TextField><br />
               <TextField
               id="outlined-select-currency-native"
               select
               label="Select Installement Available"
               value={Installemnt}
               onChange={handleChangeInstallemnt}
               SelectProps={{
                   native: true,
               }}
               >
               {<>
               <option key="No" value="No">
                   No
               </option>
               <option key="Yes" value="Yes">
                   Yes
               </option>
               </>}
               </TextField><br />
               {Installemnt === "Yes" ? <><TextField
                   id="date"
                   label="Installemnt Start Date"
                   type="date"
                   defaultValue={InstallemntStartDate}
                   inputFormat="MM/DD/YYYY"
                   // value={InstallemntStartDate}
                   onChange={handleChangeInstallemntStartDate}
                   sx={{ width: 220 }}
                   InputLabelProps={{
                   shrink: true,
                   }}
               />
               <p style={{color:'red'}}>{!InstallemntStartDate && ErrorHandling}</p>
               <TextField
                   id="date"
                   label="Installemnt End Date"
                   type="date"
                   defaultValue={InstallemntEndDate}
                   inputFormat="MM/DD/YYYY"
                   // value={InstallemntEndDate}
                   onChange={handleChangeInstallemntEndDate}
                   sx={{ width: 220 }}
                   InputLabelProps={{
                   shrink: true,
                   }}
               />
                <p style={{color:'red'}}>{ErrorInstallmentDateHandling}</p>
                <p style={{color:'red'}}>{!InstallemntEndDate && ErrorHandling}</p>
               <br /></> : <></>} 

               <TextField
                   id="outlined-select-currency"
                   select
                   label="Select Currency"
                   value={Currency}
                   onChange={handleChangeCurrency}
                   helperText="Please select your currency"
                   >
                   {currencies.map((option) => (
                       <MenuItem key={option} value={option}>
                       {option}
                       </MenuItem>
                   ))}
               </TextField><br />
               <InputLabel style={{marginLeft: '8px'}} htmlFor="outlined-adornment-amount">Total Paid Amount</InputLabel>
               <OutlinedInput style={{marginLeft: '8px'}}
               id="outlined-adornment-amount"
               value={TotalAmount}
               onChange={handleChangeTotalAmount}
               startAdornment={<InputAdornment position="start">{Currency}</InputAdornment>}
               label="Total Paid Amount"
               />      
               <p style={{color:'red'}}>{!TotalAmount && ErrorHandling}</p>
               <br />
               <TextField
                   id="date"
                   label="Payment Date"
                   type="date"
                   defaultValue={PaymentDate}
                   inputFormat="MM/DD/YYYY"
                   onChange={handleChangePaymentDate}
                   sx={{ width: 220 }}
                   InputLabelProps={{
                   shrink: true,
                   }}
               /> 
                <p style={{color:'red'}}>{!PaymentDate && ErrorHandling}</p>
                <p style={{color:'red'}}>{ErrorPaymentDateHandling}</p>
               <br />
               <TextField
                   id="status"
                   select
                   label="Select Status"
                   value={Status}
                   onChange={handleChangeStatus}
                   helperText="Please Payment Status"
                   >
                   {statusCand.map((option) => (
                       <MenuItem key={option} value={option}>
                       {option}
                       </MenuItem>
                   ))}
               </TextField>  <br />    
               <TextField
                    id="outlined-select-Source"
                    select
                    label="Please Select Lead Source"
                    value={leadSource}
                    onChange={handleChangeleadSource}
                    helperText="Please Select Lead Source"
                    >
                    {Usersource.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
                    ))}
                </TextField>
                <p style={{color:'red'}}>{isEmpty(leadSource) && ErrorHandling}</p>
                <br />
               <TextField
                   id="notes"
                   name="notes"
                   label="Notes"
                   type="text"
                   value={formik.values.notes}
                   onChange={formik.handleChange}
                   error={formik.touched.notes && Boolean(formik.errors.notes)}
                   helperText={formik.touched.notes && formik.errors.notes}
               /> <br />
                {LoadingButton === "1" ? <CircularProgress /> : <Button variant="contained" size="large" style={{marginLeft:'6%', marginTop:'15px'}} type="submit">Update</Button>}
           </form>
           {isPending && <CircularProgress />}
       </div>
   </Box>
   </Page>
   :
   <Page title="Edit Lead">
   <Box sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}}autoComplete="on">
       <div>
               <Typography align="left" variant="h4" sx={{ mb: 5 }} style={{marginLeft:'7px'}}>
               Edit Lead
               </Typography>
           <form onSubmit={formikLead.handleSubmit}>
               <>
               <TextField
                   id="name"
                   name="name"
                   label="Lead Name"
                   type="text"
                   value={formikLead.values.name}
                   onChange={formikLead.handleChange}
                   error={formikLead.touched.name && Boolean(formikLead.errors.name)}
                   helperText={formikLead.touched.name && formikLead.errors.name}
               />
                <p style={{color:'red'}}>{!formikLead.values.name && ErrorHandling}</p>
                <br />
               <TextField
                   id="email"
                   name="email"
                   label="Lead Email"
                   type="email"
                   value={formikLead.values.email}
                   onChange={formikLead.handleChange}
                   error={formikLead.touched.email && Boolean(formikLead.errors.email)}
                   helperText={formikLead.touched.email && formikLead.errors.email}
               />
               
               </>
               <><InputLabel id="demo-multiple-chip-label" style = {{marginTop:'7px', marginLeft:'9px'}}>Enter Lead Phone</InputLabel>
               <PhoneInput
               id="phone"
               name ='phone'
               label="Lead Phone"
               type="text"
                   inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true
                   }}
                   enableSearch
                   inputStyle={{height:'57px', width:'244px'}}
                   containerStyle={{marginLeft:'8px'}}
                   preferredCountries={['eg','sa','om','ae','sd','qa','ly']}
                   country={'eg'}
                   excludeCountries = {'ISR'}
                   value={Candphone}
                   onChange={setCandphone}
               />
               <p style={{color:'red'}}>{!Candphone && ErrorHandling}</p>
               <br />
               <TextField
               id="outlined-select-currency-native"
               select
               label="Select Country"
               value={country}
               onChange={handleChangeCountries}
               SelectProps={{
                   native: true,
               }}
               >
               {countryList.map((option) => (
                   <option key={option} value={option}>
                   {option}
                   </option>
               ))}
               </TextField><br />
               </>
               <InputLabel id="demo-multiple-chip-label" style = {{marginTop:'7px', marginLeft:'9px'}}>Select Packages</InputLabel>
               {data && <Select
               labelId="demo-multiple-chip-label"
               id="demo-multiple-chip"
               multiple
               displayEmpty
               value={Cktpackage}
               onChange={handleChangePackages}
               input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
               renderValue={(selected) => {
                   if (selected.length === 0) {
                       return <em>Select Packages</em>;
                   }
                   return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                   {selected.map((value) => (
                       <Chip key={value} label={value} />
                   ))}
                   </Box>

               }}
               MenuProps={MenuProps}
               style = {{marginTop:'9px', marginLeft:'9px', width:'245px'}}
               >
               {data.data.map((name) => (
                   <MenuItem
                   key={name.package_name}
                   value={name.package_name}
                   >
                   {name.package_name}
                   </MenuItem>
               ))}
               </Select>}
               <p style={{color:'red'}}>{isEmpty(Cktpackage) && ErrorHandling}</p>
               <br />
               {packagesTypesValue && <TextField
               id="outlined-select-currency-native"
               select
               label="Select Package Type"
               value={packageType}
               onChange={handleChange}
               SelectProps={{
                   native: true,
               }}
               >
                <option key="Select Package Type" value="Select Package Type">
                        Select Package Type
                </option>
               {packagesTypesValue.data.map((option) => (
                   <option key={option.package_type} value={option.package_type}>
                   {option.package_type}
                   </option>
               ))}
               </TextField>}
               <p style={{color:'red'}}>{isEmpty(packageType) && ErrorHandling}</p>
               <br />
               {isPending && isPending2 && <CircularProgress />}
               {error && error2 && <div>{error}{' '}{error2}</div>}
               <></>
               <TextField
               id="outlined-select-currency-native"
               select
               label="Select Preferred Payment Way"
               value={PaymentWay}
               onChange={handleChangePaymentWay}
               SelectProps={{
                   native: true,
               }}
               >
               {PaymentsWays.map((option) => (
                   <option key={option} value={option}>
                   {option}
                   </option>
               ))}
               </TextField>
               <p style={{color:'red'}}>{!PaymentWay && ErrorHandling}</p>
               <br />
               <TextField
               id="outlined-select-currency-native"
               select
               label="Select Installement Available"
               value={Installemnt}
               onChange={handleChangeInstallemnt}
               SelectProps={{
                   native: true,
               }}
               >
               {<>
               <option key="No" value="No">
                   No
               </option>
               <option key="Yes" value="Yes">
                   Yes
               </option>
               </>}
               </TextField>
               <br />
               <TextField
                   id="date"
                   label="Expected Payment Date"
                   type="date"
                   defaultValue={PaymentDate}
                   inputFormat="MM/DD/YYYY"
                   onChange={handleChangePaymentDate}
                   sx={{ width: 220 }}
                   InputLabelProps={{
                   shrink: true,
                   }}
               /> 
               <p style={{color:'red'}}>{!PaymentDate && ErrorHandling}</p>
               <br />
               <TextField
                   id="date"
                   label="Deal DONE Date"
                   type="date"
                   defaultValue={DealDoneDate}
                   inputFormat="MM/DD/YYYY"
                   onChange={handleChangeDealDoneDate}
                   sx={{ width: 220 }}
                   InputLabelProps={{
                   shrink: true,
                   }}
               /> 
               <p style={{color:'red'}}>{!isEmpty(DealDoneDate) && ErrorPaymentDateHandling}</p>
               <br />
               <TextField
                   id="date"
                   label="Contact Date"
                   type="date"
                   defaultValue={ContactDate}
                   inputFormat="MM/DD/YYYY"
                   onChange={handleChangeContactDate}
                   sx={{ width: 220 }}
                   InputLabelProps={{
                   shrink: true,
                   }}
               /> 
                <p style={{color:'red'}}>{ErrorPaymentDateHandling}</p>
                <p style={{color:'red'}}>{!ContactDate && ErrorHandling}</p>
               <br />
               <TextField
                   id="outlined-select-PromoCode"
                   select
                   label="Select Promo Code If Available"
                   value={PromoCode}
                   onChange={handleChangePromoCode}
                   helperText="Please select Promo Code If Available"
                   >
                   {promocode.map((option) => (
                       <MenuItem key={option} value={option}>
                       {option}
                       </MenuItem>
                   ))}
               </TextField>
               <TextField
                   id="status"
                   select
                   label="Select Status"
                   value={Status}
                   onChange={handleChangeStatus}
                   helperText="Please Lead Status"
                   >
                   {status.map((option) => (
                       <MenuItem key={option} value={option}>
                       {option}
                       </MenuItem>
                   ))}
               </TextField>  
               <p style={{color:'red'}}>{!Status && ErrorHandling}</p>
               <br />    
               <TextField
                    id="outlined-select-Source"
                    select
                    label="Please Select Lead Source"
                    value={leadSource}
                    onChange={handleChangeleadSource}
                    helperText="Please Select Lead Source"
                    >
                    {Usersource.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
                    ))}
                </TextField>
                <p style={{color:'red'}}>{isEmpty(leadSource) && ErrorHandling}</p>
                <br />
               <TextField
                   id="notes"
                   name="notes"
                   label="Notes"
                   type="text"
                   value={formikLead.values.notes}
                   onChange={formikLead.handleChange}
                   error={formikLead.touched.notes && Boolean(formikLead.errors.notes)}
                   helperText={formikLead.touched.notes && formikLead.errors.notes}
               /> <br />
                {LoadingButton === "1" ? <CircularProgress /> : <Button variant="contained" size="large" style={{marginLeft:'6%', marginTop:'15px'}} type="submit">Update</Button>}
           </form>
           {isPending && <CircularProgress />}
       </div>
   </Box>
   </Page>
  );
}
