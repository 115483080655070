// ----------------------------------------------------------------------

const usersData = JSON.parse(localStorage.getItem('data'));
const account = {
  displayName: (usersData) ? usersData.admin_name : '',
  email: (usersData) ? usersData.email : '',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
};

export default account;
