// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  {
    title: 'Add Tour +',
    path: '/dashboard/add-tour',
    icon: getIcon('streamline:travel-map-navigation-map-maps-gps'),
  },
  {
    title: 'Tours',
    path: '/dashboard/fetched-tours',
    icon: getIcon('streamline:travel-map-navigation-map-maps-gps'),
  },
  {
    title: 'Add Stop +',
    path: '/dashboard/stops',
    icon: getIcon('solar:podcast-bold'),
  },
  {
    title: 'Stops',
    path: '/dashboard/fetched-stops',
    icon: getIcon('solar:podcast-bold'),
  },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
  {
    title: 'Categories',
    path: '/dashboard/categories',
    icon: getIcon('carbon:collapse-categories'),
  },
];

export default navConfig;
