import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
// import Candidates from './pages/Candidates';
import EditUser from './pages/editUser';
import AddTour from './pages/addTour';
import AddStop from './pages/addStop';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import PackagesSettings from './pages/Categories';
import Tours from './pages/Tours';
import Stops from './pages/Stops';
import EditTour from './pages/EditTour';
import EditStop from './pages/EditStop';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        // { path: 'user', element: <User /> },
        // { path: 'products', element: <Products /> },
        { path: '/dashboard/tours', element: <AddTour /> },
        { path: '/dashboard/stops', element: <AddStop /> },
        { path: '/dashboard/categories', element: <PackagesSettings /> },
        { path: 'edit-tour/:id', element: <EditTour /> },
        { path: 'edit-stop/:id', element: <EditStop /> },
        { path: 'add-tour', element: <AddTour /> },
        { path: 'fetched-tours', element: <Tours /> },
        { path: 'fetched-stops', element: <Stops /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
