import React, { useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-control-geocoder'; // Import the leaflet-control-geocoder CSS

import SearchBarControl from './SearchBarControl';

import markerIcon from '../assets/location.png'; // Replace with your marker icon path

const Map = ({ onLocationSelect }) => {
  const mapRef = useRef(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const HandleMapClick = () => {
    const map = useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setSelectedPosition([lat, lng]);
        onLocationSelect({ latitude: lat, longitude: lng });
      },
    });

    return null;
  };

  const handleGeocoderSearch = () => {
    const input = document.getElementById('search-input');
  
    if (input && input.value) {
      L.Control.Geocoder.nominatim().geocode(input.value, (results) => {
        console.log('Geocoding results:', results);
  
        if (results && results.length > 0) {
          const { lat, lng } = results[0].center;
          console.log('Latitude:', lat, 'Longitude:', lng);
  
          if (typeof lat !== 'undefined' && typeof lng !== 'undefined') {
            setSelectedPosition([lat, lng]);
            onLocationSelect({ latitude: lat, longitude: lng });
  
            // Move the map to the geocoded location
            mapRef.current.panTo(new L.LatLng(lat, lng));
          } else {
            console.error('Invalid latitude or longitude value.');
          }
        } else {
          console.error('No geocoding results found.');
        }
      });
    }
  };
  
  
  
  
  
  
  


  
  

  return (
    <>
      <SearchBarControl onSearch={handleGeocoderSearch} /> <br />
      <MapContainer center={[0, 0]} zoom={1} ref={mapRef} style={{ height: '500px', width: '40%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <HandleMapClick />
        {selectedPosition && (
          <Marker
            position={selectedPosition}
            icon={L.icon({
              iconUrl: markerIcon,
              iconSize: [25, 41], // Size of the icon image
              iconAnchor: [12, 41], // Point of the icon anchor (relative to iconSize)
              popupAnchor: [1, -34], // Point from which the popup should open (relative to iconSize)
            })}
          >
            <Popup>
              Latitude: {selectedPosition[0]}, Longitude: {selectedPosition[1]}
            </Popup>
          </Marker>
        )}
      </MapContainer>
    </>
  );
};

export default Map;
