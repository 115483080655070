import { useState,useEffect } from "react";
import {getToken} from '../helpers';

const useFetch = (url) => {
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error,setError] = useState(null);
    const token = getToken();
    useEffect(()=>{
        const abortCont = new AbortController();
            fetch(url,{
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
              }, {signal: abortCont.signal})
            .then(res => {
                console.log(res);
                if(!res.ok){
                    if(typeof token === 'undefined' || !token) {
                        window.location.href = "/";
                    }
                    throw Error ('Could not fetch the data for that resource');
                }
                return res.json()
            })
            .then(data =>{
                setData(data);
                setIsPending(false);
                setError(null);
            }).catch(err =>{
                if(err.name === 'AbortError'){
                    console.log('fetch aborted');
                }else{
                    setIsPending(false);
                    setError(err.message);     
                }
            });
        return () => abortCont.abort();
            },[url]);

  return{data,isPending,error}
}
 
export default useFetch;