import React, { useState } from 'react';

import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import * as yup from 'yup';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, TextField, MenuItem, CircularProgress } from '@mui/material';
import { DatePicker, MuiPickersUtilsProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterDateFns';
// components
import 'react-phone-input-2/lib/style.css';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Map from './Map';
import Page from '../components/Page';
import { getApiLink, getToken } from '../helpers';



// Initialize Firebase


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const categories = [
  "",
  "On foot",
  "By car",
  "By train",
  "Events",
  "Museums",
  "Quests"
];
const states = [
  "",
  "New South Wales", 
  "Victoria",
  "Queensland", 
  "Western Australia", 
  "South Australia", 
  "Tasmania",
  "Northern Territory",
  "Canberra"
];
const countryList = [
  "Australia",
];
// Update the countryList to remove duplicates using Set
const countrySet = new Set(countryList);
const uniqueCountryList = [...countrySet];
const featured = [0, 1];

const validationSchema = yup.object({
  TourTitle: yup.string('Enter Tour Name').min(4, 'Tour Name should be of minimum 4 chars').required('*Required'),
});

async function editTour(id,credentials) {
  const url = getApiLink(`admin/update-tour/${id}`);
  const token = getToken();
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      tour_title: credentials.TourTitle,
      tour_description: credentials.TourDescription,
      tour_img: credentials.TourImage,
      category_name: credentials.TourCategory,
      country: credentials.TourCountry,
      state: credentials.TourState,
      location_name: credentials.TourLocationName,
      latitude: credentials.latitude,
      longitude: credentials.longitude,
      radius_around: credentials.TourBorderRadius,
      featured: credentials.FeaturedTour,
      created_at: credentials.CreatedAt
    })
  })
    .then(data => data.json())
}

export default function EditTour() {
  const { state } = useLocation();
  const { id } = useParams();
  const theme = useTheme();

  const [loadingButton, setLoadingButton] = useState("0");
  const [loadingButtonUploadImage, setloadingButtonUploadImage] = useState("0");
  const [uploaded, setuploaded] = useState("Upload");

  // Add Tour
  const [tourTitle, setTourTitle] = useState(state._tourtitle);
  const [tourDescription, setTourDescription] = useState(state._tourDescription);
  const [tourImage, setTourImage] = useState(state._tourimg);
  const [tourCategory, setTourCategory] = useState(state._categoryname);
  const [tourCountry, setTourCountry] = useState(state._country);
  const [tourState, setTourState] = useState(state._state);
  const [tourLocationName, setTourLocationName] = useState(state._location_name);
  const [featuredTour, setFeaturedTour] = useState(state._featured);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: state._latitude, // Set default latitude from state
    longitude: state._longitude, // Set default longitude from state
  });
  const [tourBorderRadius, settourBorderRadius] = useState(state._radiusaround);
  const [createdAt, setCreatedAt] = useState(state._createdat);

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleChangeTourTitle = (event) => {
    setTourTitle(event.target.value);
  }

  const handleChangeTourDescription = (event) => {
    setTourDescription(event.target.value);
  }

  const handleChangeTourImage = (event) => {
    setTourImage(event.target.value);
  }

  const handleChangeTourCategory = (event) => {
    setTourCategory(event.target.value);
  };

  const handleChangeTourCountry = (event) => {
    setTourCountry(event.target.value);
  };

  const handleChangeTourState = (event) => {
    setTourState(event.target.value);
  };

  const handleChangeTourLocationName = (event) => {
    setTourLocationName(event.target.value);
  };

  const handleChangetourBorderRadius= (event) => {
    settourBorderRadius(event.target.value);
  };

  const handleChangeFeaturedTour = (event) => {
    setFeaturedTour(event.target.value);
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleCreatedAtChange = (event) => {
    setCreatedAt(event.target.value);
  };

  const handleUpload = () => {
    // Initialize Firebase with your Firebase Config
    const firebaseConfig = {
      apiKey: "AIzaSyCTr2P3vlKYK_W6ll0wrSjieuNgAoR2gA8",
      authDomain: "storytowns-71033.firebaseapp.com",
      projectId: "storytowns-71033",
      storageBucket: "storytowns-71033.appspot.com",
      messagingSenderId: "1017802746586",
      appId: "1:1017802746586:web:a327f39120ec06b001d3e3",
      measurementId: "G-8VT64L98WE"
    };

    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, selectedFile.name);

    uploadBytes(storageRef, selectedFile).then((snapshot) => {
      console.log('Image uploaded successfully');
      setloadingButtonUploadImage("1");
      // Get the public URL of the uploaded image
      getDownloadURL(storageRef).then((downloadURL) => {
        setTourImage(downloadURL);
        setloadingButtonUploadImage("0");
        setuploaded("Uploaded Successfully ✓");
        console.log('Download URL:', downloadURL);
        // You can use the downloadURL as the URL of the uploaded image
      });
    });
  };
  
  
  const handleSubmit = async () => {
    // Perform form validation
    if (!tourTitle || tourTitle.trim() === '') {
      alert('Tour Title is required.');
      return;
    }
  
    if (!tourDescription || tourDescription.trim() === '') {
      alert('Tour Description is required.');
      return;
    }
  
    if (!tourImage || tourImage.trim() === '') {
      alert('Tour Image URL is required.');
      return;
    }
  
    if (!tourCategory || tourCategory.trim() === '') {
      alert('Tour Category is required.');
      return;
    }
  
    if (!tourCountry || tourCountry.trim() === '') {
      alert('Country is required.');
      return;
    }
  
    if (!tourState || tourState.trim() === '') {
      alert('State is required.');
      return;
    }
  
    if (!tourLocationName || tourLocationName.trim() === '') {
      alert('Location Name is required.');
      return;
    }
  
    if (!selectedLocation) {
      alert('Please select a location on the map.');
      return;
    }
  
    // if (!tourBorderRadius || Number.isNaN(tourBorderRadius)) {
    //   alert('Border Radius must be a valid number.');
    //   return;
    // }
  
    // If all validations pass, proceed with the form submission
    setLoadingButton("1");
    const response = await editTour(id,{
      TourTitle: tourTitle,
      TourDescription: tourDescription,
      TourImage: tourImage,
      TourCategory: tourCategory,
      TourCountry: tourCountry,
      TourState: tourState,
      TourLocationName: tourLocationName,
      FeaturedTour: featuredTour,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
      TourBorderRadius: tourBorderRadius,
      CreatedAt: createdAt
    });
  
    if (response.status) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 1000,
      }).then((value) => {
        window.location.href = "/dashboard/fetched-tours";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  
    setLoadingButton("0");
  };
  

  return (
    <Page title="Add New Tour">
      <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} autoComplete="on">
        <div>
          <Typography align="left" variant="h4" sx={{ mb: 5 }} style={{ marginLeft: '7px' }}>
            Edit Tour
          </Typography>
          <>
            <TextField
              id="tourTitle"
              name="tourTitle"
              label="Tour Title"
              type="text"
              value={tourTitle}
              onChange={handleChangeTourTitle}
            /><br />
            <TextField
              id="tourDescription"
              name="tourDescription"
              label="Tour Description"
              type="text"
              value={tourDescription}
              onChange={handleChangeTourDescription}
            /><br />
            <TextField
              id="tourImg"
              name="tourImg"
              label="Uploaded Tour Image"
              type="text" // Change this from "file" to "text"
              value={tourImage}
              onChange={handleChangeTourImage}
              InputProps={{readOnly: true, }}
            />
            <br />
            <div>
            <TextField
              id="tourImg"
              name="tourImg"
              type="file"
              onChange={handleFileChange}
              helperText="* Upload new Image if you want to update the current one"
            />
            {loadingButtonUploadImage === "1" ? <CircularProgress /> : <Button
              variant="contained"
              size="large"
              style={{ marginLeft: '2%', marginTop: '10px' }}
              type="button"
              onClick={handleUpload}
            >
              {uploaded}
            </Button>}
            </div>
            <br />
            <TextField
              id="outlined-select-currency-native"
              select
              label=""
              value={tourCategory}
              onChange={handleChangeTourCategory}
              SelectProps={{
                native: true,
              }}
              helperText="Select Tour Category"
            >
              {categories.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Country"
              value={tourCountry}
              onChange={handleChangeTourCountry}
              SelectProps={{
                native: true,
              }}
            >
              {uniqueCountryList.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="state"
              select
              label=""
              value={tourState}
              onChange={handleChangeTourState}
              SelectProps={{
                native: true,
              }}
              helperText="Select Tour State"
            >
              {states.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="locationName"
              name="locationName"
              label="Location Name"
              type="text"
              value={tourLocationName}
              onChange={handleChangeTourLocationName}
            /><br />
            <TextField
              id="borderRadius"
              name="borderRadius"
              label="Border Radius"
              type="text"
              value={tourBorderRadius}
              onChange={handleChangetourBorderRadius}
              helperText="* Enter In KM (Ignore if not required)"
            /><br />
            <TextField
            id="createdAt"
            label= {`* Created At ${state._createdat}`}
            type="date"
            value={createdAt}
            onChange={handleCreatedAtChange}
            InputLabelProps={{
              shrink: true,
            }}
            />
            <br />
            <TextField
              id="Featured"
              select
              label="Mark as Featured"
              value={featuredTour}
              onChange={handleChangeFeaturedTour}
              helperText="Mark as Featured"
            >
              {featured.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField> <br />
            <div>
      <h1>Search Location</h1>
      <h5>* Choose Another location if you want to update the current one</h5>
      <Map onLocationSelect={handleLocationSelect} />
      {selectedLocation && (
        <div>
          <h2>Selected Location:</h2>
          <p>Latitude: {selectedLocation.latitude}</p>
          <p>Longitude: {selectedLocation.longitude}</p>
        </div>
      )}
    </div> <br />
            {loadingButton === "1" ? <CircularProgress /> : <Button
              variant="contained"
              size="large"
              style={{ marginLeft: '6%', marginTop: '15px' }}
              type="button"
              onClick={handleSubmit}
            >
              Update
            </Button>}
          </>
        </div>
      </Box>
    </Page>
  );
}
