import * as React from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, CircularProgress } from '@mui/material';
import { random } from 'lodash';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import useFetch from "./useFetch";
import {getApiLink, getToken} from '../helpers';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const {data:tours,isPending,error} = useFetch(getApiLink('get-all-tours'));
  const {data:stops,isPending2,error2} = useFetch(getApiLink('get-all-stops'));

  // const count = React.useRef(PackagesList[0]);
  // async function getPackagesList(){
  //   await allPackages.data.map((option) => (
  //     !(allPackages.total <= PackagesList.length) ? PackagesList.push(option.package_name.split(" ").join("")) : PackagesList
  //   ));
    
  // }
  // getPackagesList();
  // console.log(PackagesList);
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
 
        <Grid container spacing={3}>         
          <Grid item xs={12} sm={6} md={3}>
            {tours && <AppWidgetSummary title="Total Tours" total={tours.total} icon={'streamline:travel-map-navigation-map-maps-gps'} />}
            {isPending && <CircularProgress />}
            {error && <div>{error}</div>}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {stops && <AppWidgetSummary title="Total Stops" total={stops.total} color="info" icon={'solar:podcast-bold'} />}
            {isPending2 && <CircularProgress />}
            {error2 && <div>{error2}</div>}
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            {leads && <AppWidgetSummary title="Leads" total={leads.total} color="warning" icon={'ant-design:user-add-outlined'} />}
            {isPending3 && <CircularProgress />}
            {error3 && <div>{error3}</div>}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {candidates && <AppWidgetSummary title="CKT Candidates" total={candidates.total} color="error" icon={'fa6-solid:user-doctor'} />}
            {isPending4 && <CircularProgress />}
            {error4 && <div>{error4}</div>}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
          {mostPaidPackagesThroughMonths && <AppWebsiteVisits
              title="Packages Growth Through Last Months"
              subheader=""
              chartLabels={[
                '01-01-2021',
                '02-01-2021',
                '03-01-2021',
                '04-01-2021',
                '05-01-2021',
                '06-01-2021',
                '07-01-2021',
                '08-01-2021',
                '09-01-2021',
                '10-01-2021',
                '11-01-2021',
                '12-01-2021',
                '01-01-2022',
                '02-01-2022',
                '03-01-2022',
                '04-01-2022',
                '05-01-2022',
                '06-01-2022',
                '07-01-2022',
                '08-01-2022',
                '09-01-2022',
                '10-01-2022',
                '11-01-2022',
                '12-01-2022',
                '01-01-2023',
                '02-01-2023',
                '03-01-2023',
                '04-01-2023',
                '05-01-2023',
                '06-01-2023',
                '07-01-2023',
                '08-01-2023',
                '09-01-2023',
                '10-01-2023',
                '11-01-2023',
                '12-01-2023',
              ]}
              chartData={
                mostPaidPackagesThroughMonths.total.map((n) => ((
                  {
                    // $statsType = ["column", "area", "line"];
                    // $statsFill = ["solid", "gradient", "solid"];
                    name: n.key,
                    type: 'line',
                    fill: 'solid',
                    data: [n.value.jan2021,n.value.feb2021,n.value.march2021,n.value.april2021,n.value.may2021,n.value.june2021,
                      n.value.jul2021,n.value.aug2021,n.value.sep2021,n.value.oct2021,n.value.nov2021,n.value.dec2021,n.value.jan2022,n.value.feb2022,n.value.march2022,n.value.april2022,n.value.may2022,n.value.june2022,n.value.jul2022,n.value.aug2022,n.value.sep2022,n.value.oct2022,n.value.nov2022,n.value.dec2022,n.value.jan2023,n.value.feb2023,n.value.march2023,n.value.april2023,n.value.may2023,n.value.june2023,n.value.jul2023,n.value.aug2023,n.value.sep2023,n.value.oct2023,n.value.nov2023,n.value.dec2023],
                  }
               )))
              }
            />}
            {isPending7 && <CircularProgress />}
            {error7 && <div>{error7}</div>}
          </Grid>

          
          <Grid item xs={12} md={12} lg={8}>
          {mostPaidPackages && 
          <AppCurrentVisits
              title="Most Paid Packages"
              chartData={
                mostPaidPackages.total.map((n) => ((
                  {label: n.key, value: n.value }
              )))}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />}
            {isPending6 && <CircularProgress />}
            {error6 && <div>{error6}</div>}
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            {GetMostcountriesPurchaseTraining && <AppConversionRates
              title="Most countries purchase our trainings"
              subheader=""
              chartData={                
                GetMostcountriesPurchaseTraining.total.map((n) => ((
                {label: n.country, value: n.total }
            )))}
            />}
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={4}>
            {GetLatestCandidates && <AppOrderTimeline
              title="Latest Candidates Purchased"
              list={
                GetLatestCandidates.total.map((n, index) => ((
                  {id: n.id, title: [n.cand_email , <br />, n.phone, <br />, `Purchased: ${n.package_type}`],  type: `order${index + 1}`, time: n.updated_at}
              )))
              }
            />}
          </Grid>
          {Latestapplicants && <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title =  {`Applicants Through Website (Total: ${Latestapplicants.total})`}
              list={
              //   [...Array(5)].map((_, index) => ({
              //   id: faker.datatype.uuid(),
              //   title: faker.name.jobTitle(),
              //   description: faker.name.jobTitle(),
              //   image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
              //   postedAt: faker.date.recent(),
              // }))          
              Latestapplicants.data.map((n, index) => ((
                {
                  id: n.id,
                  title: `${n.fullName}${' | '}${n.phone}`,
                  description:`${n.email}${' | '}"${n.chosen} Package"`,
                  image: `/static/mock-images/covers/websiteUser.png`,
                  postedAt: n.created_at,
                }
            )))
            
            
            }
            />
            {isPending10 && <CircularProgress />}
            {error10 && <div>{error10}</div>}
          // </Grid>} */}


          
          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic On Platforms"
              list={[
                {
                  name: 'FaceBook',
                  value: 11.063,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Instagram',
                  value: 739,
                  icon: <Iconify icon={'logos:instagram-icon'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 152,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 33,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
                {
                  name: 'Telegram',
                  value: 620,
                  icon: <Iconify icon={'logos:telegram'} color="#1C9CEA" width={32} height={32} />,
                },
                {
                  name: 'CKT Website',
                  value: 2000,
                  icon: <Iconify icon={'dashicons:admin-site-alt3'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
     </Container>
    </Page>
  );
}
