import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import swal from 'sweetalert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import parse from 'html-react-parser';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, TextField , MenuItem, CircularProgress } from '@mui/material';
// components
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
// components
import {getApiLink, getToken} from '../helpers';
import Page from '../components/Page';
import useFetch from "./useFetch";




export default function Categories() {
  return (
    <Page title="Packages Settings">
        <Box sx={{'& .MuiTextField-root': { m: 1, width: '25ch' },}}autoComplete="on">
                <InputLabel id="demo-multiple-chip-label" style = {{marginTop:'7px', marginLeft:'9px'}}>Under Construction</InputLabel>
        </Box>
    </Page>
  );
}
