export function getApiLink(link) {
    const apiUrl = 'https://www.storytowns.net/api/';
    return apiUrl + link;
}
export function getToken() {
    const token = localStorage.getItem('token');
    return (token && typeof token !== 'undefined' && token !== 'undefined') ? token : null;
}
/*
http://storytowns-dev-2-test.clinicalktraining.com
*/