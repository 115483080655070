import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {CircularProgress, TextField, Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { sentenceCase } from 'change-case';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Iconify from '../components/Iconify';
import Label from '../components/Label';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import useFetch from "./useFetch";
import {getApiLink, getToken} from '../helpers';

// ----------------------------------------------------------------------

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

// const {data,isPending,error} = useFetch(getApiLink('admin/get-all-ckt-users'));
const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'id',
  },
  {
    id: 'country',
    numeric: true,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'state',
    numeric: true,
    disablePadding: false,
    label: 'State',
  },
  {
    id: 'location_name',
    numeric: true,
    disablePadding: false,
    label: 'Location Name',
  },
  {
    id: 'category_name',
    numeric: true,
    disablePadding: false,
    label: 'Category Name',
  },
  {
    id: 'tour_title',
    numeric: true,
    disablePadding: false,
    label: 'Tour Title',
  },
  {
    id: 'featured',
    numeric: true,
    disablePadding: false,
    label: 'Featured',
  },
  {
    id: 'stop_count',
    numeric: true,
    disablePadding: false,
    label: 'Stops no.',
  },
  {
    id: 'created_at',
    numeric: true,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'updated_at',
    numeric: true,
    disablePadding: false,
    label: 'Updated At',
  },
  {
    id: 'Edit',
    numeric: true,
    disablePadding: false,
    label: 'Edit Tour.',
  },
  {
    id: 'Delete',
    numeric: true,
    disablePadding: false,
    label: 'Delete Tour.',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const AddTour = () => {
  window.location.href = `/dashboard/add-tour`;
}
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          StoryTowns Tours
        </Typography>
      )}


      <Tooltip title="Add New Tour">
        <Button variant="contained" onClick={()=> AddTour('add-lead')} startIcon={<Iconify icon="eva:plus-fill" />}>
          Tour
        </Button>
      </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function Tours() {
  const {data,isPending,error} = useFetch(getApiLink('get-all-tours'));
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('fullName');
  const [selected, setSelected] = React.useState([]);
  const [LeadIdsList, setLeadIdsList] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchedVal, setSearchedVal] = React.useState("");
  const [rqloading, setRqloading] = React.useState(false);
  const ref = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const editTour = (id,country,state,tourimg,tourDescription,locationname,categoryname,tourtitle,latitude,longitude,radiusaround,featured,createdat,updatedat) => {
    navigate(`/dashboard/edit-tour/${id}`,
        {
            state: {
              _country:country,
              _state:state,
              _tourimg:tourimg,
              _tourDescription:tourDescription,
              _location_name:locationname,
              _categoryname:categoryname,
              _tourtitle:tourtitle,
              _latitude:latitude,
              _longitude:longitude,
              _radiusaround:radiusaround,
              _featured:featured,
              _createdat:createdat,
              _updatedat:updatedat
            }
        });
  }
  const token = getToken();
  const deleteLead = (id)=> {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
                setRqloading(true);
                fetch(getApiLink(`admin/delete-tour/${id}`), {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                }).then(() => {
                  window.location.href = "/dashboard/fetched-tours";
                });
          }
        },
        {
          label: 'No',
        }
      ]
    });

  }
  const deleteAllTours = ()=> {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this. You will delete all Selected Tours',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
                setRqloading(true);
                fetch(getApiLink('admin/delete-all-tours'), {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                  body: JSON.stringify({
                    idsList: LeadIdsList,
                  })
                }).then(() => {
                  window.location.href = "/dashboard/fetched-tours";
                });
          }
        },
        {
          label: 'No',
        }
      ]
    });

  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.id);
      data.data.map((n) => LeadIdsList.push(n.id));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    LeadIdsList.push(name);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.data.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      {data && <Paper sx={{ width: '100%', mb: 2 }}>
      <TextField label="Search by Tour Title..." onChange={(e) => setSearchedVal(e.target.value)} />
        <EnhancedTableToolbar numSelected={selected.length} />
        {selected.length > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon onClick={deleteAllTours}/>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.data.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(data.data, getComparator(order, orderBy))
                  .filter((row) =>
                  // note that I've incorporated the searchedVal length check here
                  !searchedVal.length || row.tour_title
                    .toString()
                    .toLowerCase()
                    .includes(searchedVal.toString().toLowerCase()) 
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.country}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id)}
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.country ?? <b>—</b>}</TableCell>
                      <TableCell align="right">{row.state}</TableCell>
                      <TableCell align="right">{row.location_name}</TableCell>
                      <TableCell align="right">{row.category_name}</TableCell>
                      <TableCell align="right">{row.tour_title}</TableCell>
                      {/* <TableCell align="right">{
                          <Label variant="ghost" color={(row.status === 'Pending' && 'error') || 'success'}>
                                {sentenceCase(row.status)}
                          </Label>
                      }</TableCell> */}
                      <TableCell align="right">{row.featured}</TableCell>
                      <TableCell align="right">{row.stop_count}</TableCell>
                      <TableCell align="right">{row.created_at}</TableCell>
                      <TableCell align="right">{row.updated_at}</TableCell>
                      <TableCell align="right">
                          { isItemSelected ? <></> : 
                          
                      <>
                        <IconButton ref={ref} 
                        onClick={()=> editTour(
                          row.id,row.country,
                          row.state,
                          row.tour_img,
                          row.tour_description,
                          row.location_name,row.category_name,
                          row.tour_title,row.latitude,
                          row.longitude,
                          row.radius_around,
                          row.featured
                          ,row.created_at,row.updated_at             
                          )} 
                        
                        style={{ backgroundColor: 'transparent' }} >
                          <MenuItem sx={{ color: 'text.secondary' }}>
                              <ListItemIcon>
                                <Iconify icon="eva:edit-fill" width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
                          </MenuItem>
                        </IconButton>
                      </>
                        }
                      </TableCell>
                      <TableCell align="right">
                          { isItemSelected ? <></> : 
                          
                      <>
                        {rqloading ? <CircularProgress /> :<IconButton ref={ref} onClick={() => deleteLead(row.id)} style={{ backgroundColor: 'transparent' }} >
                          <MenuItem sx={{ color: 'text.secondary' }}>
                            <ListItemIcon>
                              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                          </MenuItem>
                        </IconButton>}
                      </>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}
      {isPending && <CircularProgress />}
      {error && <div>{error}</div>}
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>

  );
}
