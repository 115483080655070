import React from 'react';
import { Button, Typography, Container, Box, TextField , MenuItem, CircularProgress } from '@mui/material';

const SearchBarControl = ({ onSearch }) => {
  return (
    <div>
      <TextField
        id="search-input"
        name="search-input"
        label="Location Name"
        placeholder="Search location..."
      />
      <Button variant="contained" size="large" style={{marginLeft:'2%', marginTop:'10px'}} type="submit" onClick={onSearch}>Search</Button>
    </div>
  );
};

export default SearchBarControl;
