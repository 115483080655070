import React, { useState, useEffect } from 'react';

import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import * as yup from 'yup';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, TextField, MenuItem, CircularProgress } from '@mui/material';
// components
import 'react-phone-input-2/lib/style.css';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Map from './Map';
import Page from '../components/Page';
import useFetch from './useFetch';
import { getApiLink, getToken } from '../helpers';


// Initialize Firebase


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const categories = [
  "",
  "On foot",
  "By car",
  "By train",
  "Events",
  "Museums",
  "Quests"
];
const states = [
  "",
  "New South Wales", 
  "Victoria",
  "Queensland", 
  "Western Australia", 
  "South Australia", 
  "Tasmania",
  "Northern Territory",
  "Canberra"
];
const countryList = [
  "Australia",
];
// Update the countryList to remove duplicates using Set
const countrySet = new Set(countryList);
const uniqueCountryList = [...countrySet];
const featured = [0, 1];

const validationSchema = yup.object({
  stopTitle: yup.string('Enter stop Name').min(4, 'stop Name should be of minimum 4 chars').required('*Required'),
});

async function editStop(id, credentials) {
  const url = getApiLink(`admin/update-stop/${id}`);
  const token = getToken();
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      stop_title: credentials.StopTitle,
      stop_link: credentials.StopLink,
      stop_duration: credentials.StopDuration,
      stop_img: credentials.StopImage,
      category_name: credentials.StopCategory,
      tour_title:credentials.TourTitle,
      country: credentials.StopCountry,
      state: credentials.StopState,
      location_name: credentials.StopLocationName,
      latitude: credentials.latitude,
      longitude: credentials.longitude,
      radius_around: credentials.StopBorderRadius,
      created_at: credentials.CreatedAt
    })
  })
    .then(data => data.json())
}

export default function EditStop() {
  const { state } = useLocation();
  const { id } = useParams();
  const theme = useTheme();

  const [loadingButton, setLoadingButton] = useState("0");
  const [loadingButtonUploadImage, setloadingButtonUploadImage] = useState("0");
  const [uploaded, setuploaded] = useState("Upload");

  // Add stop
  const [stopTitle, setstopTitle] = useState(state._stoptitle);
  const [stopLink, setstopLink] = useState(state._stoplink);
  const [stopDuration, setstopDuration] = useState(state._stopduration);
  const [stopImage, setstopImage] = useState(state._stopimg);
  const [stopCategory, setstopCategory] = useState(state._categoryname);
  const [stopCountry, setstopCountry] = useState(state._country);
  const [stopState, setstopState] = useState(state._state);
  const [tourTitle, settourTitle] = useState(state._tourtitle);
  const [stopLocationName, setstopLocationName] = useState(state._location_name);
  const [featuredstop, setFeaturedstop] = useState([]);
  const [stopBorderRadius, setstopBorderRadius] = useState(state._radiusaround);
  const [selectedFile, setSelectedFile] = useState(null);
  const [createdAt, setCreatedAt] = useState(state._createdat);
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: state._latitude, // Set default latitude from state
    longitude: state._longitude, // Set default longitude from state
  });

  const [toursTitles, setToursTitles] = useState([]);

  useEffect(() => {
    // Function to fetch the tour titles
    const fetchTourTitles = async () => {
      try {
        const response = await fetch(getApiLink("get-all-tours"));
        if (!response.ok) {
          throw new Error('Failed to fetch tour titles');
        }
        const data = await response.json();
        // Extract the tour titles from the data and store them in the toursTitles state
        const titles = data.data.map((tour) => tour.tour_title);
        setToursTitles(titles);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTourTitles();
  }, []);



  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
  };

  const handleChangestopTitle = (event) => {
    setstopTitle(event.target.value);
  }

  const handleChangestopLink = (event) => {
    setstopLink(event.target.value);
  }

  const handleChangestopDuration = (event) => {
    setstopDuration(event.target.value);
  }

  const handleChangestopImage = (event) => {
    setstopImage(event.target.value);
  }

  const handleChangestopCategory = (event) => {
    setstopCategory(event.target.value);
  };

  const handleChangestopCountry = (event) => {
    setstopCountry(event.target.value);
  };

  const handleChangetourTitle = (event) => {
    settourTitle(event.target.value);
  };
  
  const handleChangestopState = (event) => {
    setstopState(event.target.value);
  };

  const handleChangestopLocationName = (event) => {
    setstopLocationName(event.target.value);
  };

  const handleChangestopBorderRadius= (event) => {
    setstopBorderRadius(event.target.value);
  };

  const handleChangeFeaturedstop = (event) => {
    setFeaturedstop(event.target.value);
  };
 

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleCreatedAtChange = (event) => {
    setCreatedAt(event.target.value);
  };

  const handleUpload = () => {
    // Initialize Firebase with your Firebase Config
    const firebaseConfig = {
      apiKey: "AIzaSyCTr2P3vlKYK_W6ll0wrSjieuNgAoR2gA8",
      authDomain: "storytowns-71033.firebaseapp.com",
      projectId: "storytowns-71033",
      storageBucket: "storytowns-71033.appspot.com",
      messagingSenderId: "1017802746586",
      appId: "1:1017802746586:web:a327f39120ec06b001d3e3",
      measurementId: "G-8VT64L98WE"
    };

    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const storageRef = ref(storage, selectedFile.name);

    uploadBytes(storageRef, selectedFile).then((snapshot) => {
      console.log('Image uploaded successfully');
      setloadingButtonUploadImage("1");
      // Get the public URL of the uploaded image
      getDownloadURL(storageRef).then((downloadURL) => {
        setstopImage(downloadURL);
        setloadingButtonUploadImage("0");
        setuploaded("Uploaded Successfully ✓");
        console.log('Download URL:', downloadURL);
        // You can use the downloadURL as the URL of the uploaded image
      });
    });
  };
  
  
  const handleSubmit = async () => {
    // Perform form validation
    if (!stopTitle || stopTitle.trim() === '') {
      alert('Stop Title is required.');
      return;
    }
  
    if (!stopLink || stopLink.trim() === '') {
      alert('Stop Description is required.');
      return;
    }

    if (!stopDuration || stopDuration.trim() === '') {
        alert('Stop Duration is required.');
        return;
    }
  
    if (!stopImage || stopImage.trim() === '') {
      alert('Stop Image URL is required.');
      return;
    }

    if (!tourTitle || tourTitle.trim() === '') {
        alert('Tour Title is required.');
        return;
    }
  
    if (!stopCategory || stopCategory.trim() === '') {
      alert('Stop Category is required.');
      return;
    }
  
    if (!stopCountry || stopCountry.trim() === '') {
      alert('Country is required.');
      return;
    }
  
    if (!stopState || stopState.trim() === '') {
      alert('State is required.');
      return;
    }
  
    if (!stopLocationName || stopLocationName.trim() === '') {
      alert('Location Name is required.');
      return;
    }
  
    if (!selectedLocation) {
      alert('Please select a location on the map.');
      return;
    }
  
    if (!stopBorderRadius || Number.isNaN(stopBorderRadius)) {
      alert('Border Radius must be a valid number.');
      return;
    }
  
    // If all validations pass, proceed with the form submission
    setLoadingButton("1");
    const response = await editStop(id,{
      StopTitle: stopTitle,
      StopLink: stopLink,
      StopDuration: stopDuration,
      StopImage: stopImage,
      StopCategory: stopCategory,
      TourTitle: tourTitle,
      StopCountry: stopCountry,
      StopState: stopState,
      StopLocationName: stopLocationName,
      Featuredstop: featuredstop,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
      StopBorderRadius: stopBorderRadius,
      CreatedAt: createdAt
    });
  
    if (response.status) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 1000,
      }).then((value) => {
        window.location.href = "/dashboard/fetched-stops";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  
    setLoadingButton("0");
  };
  

  return (
    <Page title="Add New stop">
      <Box sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} autoComplete="on">
        <div>
          <Typography align="left" variant="h4" sx={{ mb: 5 }} style={{ marginLeft: '7px' }}>
            Edit Stop
          </Typography>
          <>
            <TextField
              id="stopTitle"
              name="stopTitle"
              label="Stop Title"
              type="text"
              value={stopTitle}
              onChange={handleChangestopTitle}
            /><br />
            <TextField
              id="stopLink"
              name="stopLink"
              label="Stop Link"
              type="text"
              value={stopLink}
              onChange={handleChangestopLink}
            /><br />
            <TextField
              id="stopDuration"
              name="stopDuration"
              label="Stop Duration"
              type="text"
              value={stopDuration}
              onChange={handleChangestopDuration}
              helperText="e.g. 0:08:21"
            /><br />
            <TextField
              id="stopImg"
              name="stopImg"
              label="Stop Image"
              type="text" // Change this from "file" to "text"
              value={stopImage}
              onChange={handleChangestopImage}
              InputProps={{readOnly: true, }}
            />
            <br />
            <div>
            <TextField
              id="stopImg"
              name="stopImg"
              type="file"
              onChange={handleFileChange}
              helperText="* Upload new Image if you want to update the current one"
            />
            {loadingButtonUploadImage === "1" ? <CircularProgress /> : <Button
              variant="contained"
              size="large"
              style={{ marginLeft: '2%', marginTop: '10px' }}
              type="button"
              onClick={handleUpload}
            >
              {uploaded}
            </Button>}
            </div>
            <br />
            <TextField
              id="outlined-select-currency-native"
              select
              label=""
              value={tourTitle}
              onChange={handleChangetourTitle}
              SelectProps={{
                native: true,
              }}
              helperText="Select Which Tour Belongs To"
            >
              {toursTitles.map((option) => (                
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="outlined-select-currency-native"
              select
              label=""
              value={stopCategory}
              onChange={handleChangestopCategory}
              SelectProps={{
                native: true,
              }}
              helperText="Select stop Category"
            >
              {categories.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="outlined-select-currency-native"
              select
              label="Select Country"
              value={stopCountry}
              onChange={handleChangestopCountry}
              SelectProps={{
                native: true,
              }}
            >
              {uniqueCountryList.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="outlined-select-currency-native"
              select
              label=""
              value={stopState}
              onChange={handleChangestopState}
              SelectProps={{
                native: true,
              }}
              helperText="Select stop Category"
            >
              {states.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
            <br />
            <TextField
              id="locationName"
              name="locationName"
              label="Location Name"
              type="text"
              value={stopLocationName}
              onChange={handleChangestopLocationName}
            /><br />
             <TextField
            id="createdAt"
            label= {`* Created At ${state._createdat}`}
            type="date"
            value={createdAt}
            onChange={handleCreatedAtChange}
            InputLabelProps={{
              shrink: true,
            }}
            />
            <br />
            <TextField
              id="borderRadius"
              name="borderRadius"
              label="Border Radius"
              type="text"
              value={stopBorderRadius}
              onChange={handleChangestopBorderRadius}
              helperText="* Enter In KM"
            /><br />
            <div>
      <h1>Search Location</h1>
      <h5>* Choose Another location if you want to update the current one</h5>
      <Map onLocationSelect={handleLocationSelect} />
      {selectedLocation && (
        <div>
          <h2>Selected Location:</h2>
          <p>Latitude: {selectedLocation.latitude}</p>
          <p>Longitude: {selectedLocation.longitude}</p>
        </div>
      )}
    </div> <br />
            {loadingButton === "1" ? <CircularProgress /> : <Button
              variant="contained"
              size="large"
              style={{ marginLeft: '6%', marginTop: '15px' }}
              type="button"
              onClick={handleSubmit}
            >
              Update
            </Button>}
          </>
        </div>
      </Box>
    </Page>
  );
}
