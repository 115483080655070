// routes
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Login from './pages/Login';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

// ----------------------------------------------------------------------
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTr2P3vlKYK_W6ll0wrSjieuNgAoR2gA8",
  authDomain: "storytowns-71033.firebaseapp.com",
  projectId: "storytowns-71033",
  storageBucket: "storytowns-71033.appspot.com",
  messagingSenderId: "1017802746586",
  appId: "1:1017802746586:web:a327f39120ec06b001d3e3",
  measurementId: "G-8VT64L98WE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default function App() {
  const data = localStorage.getItem('data');
  const token = localStorage.getItem('token');
  if((!data && !token) || (typeof token === 'undefined') || (!token) || (!data) || (typeof data === 'undefined')) {
    return <Login />
  }
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
